.loader_area {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    background: #ffffff;
    position: fixed;
    z-index: 1000;
    img {
        width: auto;
        height: 40px;
        display: block;
    }
}