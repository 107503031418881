.logout_confirm_container {
  text-align: center;
  margin: 20px;
}

.logout_button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  z-index: 9999;
}

.confirmation_window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 9999;
  max-width: 400px;
  width: 80%;
}

.confirmation_message {
  margin-bottom: 10px;
}

.confirm_button,
.cancel_button {
  margin-right: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirm_button {
  background-color: #28a745;
  color: #fff;
}

.cancel_button {
  background-color: #dc3545;
  color: #fff;
}

.logout_confirm_container {
    height: 80px;
    background: none;
    border: none;
    padding: 0 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 30px;
    font-weight: 400;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    cursor: pointer;
    opacity: 1;
    box-shadow: none;
    // border-left: solid 1px rgba(255,255,255,0.15);
}
.logout_button {
  height: 80px;
  background: none;
  border: none;
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 30px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;
  opacity: 1;
  box-shadow: none;
  // border-left: solid 1px rgba(255,255,255,0.15);
}