.header_block {
    width: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    box-shadow: 0 0 5px rgba(0,0,0,0.15);
}
.main_menu_block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    position: relative;    
    background: #1C2260;
    .menu_left {
        width: auto;
        display: flex;
        align-items: center;
        padding: 0 30px 0 0;
        .logo {
            width: auto;
            display: flex;
            align-items: center;
            padding: 20px 0px;
            text-decoration: none;
            img {
                width: auto;
                display: block;
                height: 40px;
            }
            span {
                width: auto;
                display: block;
                font-size: 18px;
                line-height: 30px;
                font-weight: 500;
                color: #ffffff;
                text-decoration: none;
                margin: 0 0 0 10px;
                text-transform: uppercase;
            }
        }
        .role {
            width: auto;
            display: block;
            margin: 0 0 0 15px;
            font-size: 14px;
            line-height: 30px;
            font-weight: 400;
            color: #ffffff;
            opacity: 0.6;
            text-decoration: none;
        }
    }
    
    .menu_right {
        width: auto;
        display: flex;
        align-items: center;
        padding: 0 0 0 30px;
        .logout {
            height: 80px;
            background: none;
            border: none;
            padding: 0 0 0 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            line-height: 30px;
            font-weight: 400;
            color: #ffffff;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            cursor: pointer;
            box-shadow: none;
            border-left: solid 1px rgba(255,255,255,0.15);
            transition: all ease-in-out 0.5s;
        }
        .settings {
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 20px;
            border-left: solid 1px rgba(255,255,255,0.15);
            transition: all ease-in-out 0.5s;
            svg path {
                stroke-opacity: 0.6;
            }
            &:hover {
                svg path {
                    stroke-opacity: 1;
                }
            }
        }
        ul {
            width: auto;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0 40px;
            li {
                width: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                a {
                    font-size: 14px;
                    line-height: 80px;
                    font-weight: 500;
                    color: #ffffff;
                    padding: 0;
                    text-decoration: none;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                    opacity: 0.7;
                    position: relative;
                    transition: all ease-in-out 0.5s;
                    &:hover, &.active {
                        opacity: 1;
                    }
                }
                & + li {
                    margin: 0 0 0 40px;
                }
            }
        }
    }
}
.sub_menu_block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    background: #ffffff;
    ul {
        width: auto;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        li {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            a {
                display: flex;
                align-items: center;
                font-size: 12px;
                line-height: 60px;
                font-weight: 500;
                color: #99a8bc;
                padding: 0;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                position: relative;
                transition: all ease-in-out 0.5s;
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 0;
                    bottom: 0;
                    left: 0;
                    background: #1C2260;
                    transition: all ease-in-out 0.5s;
                }
                svg {
                    height: auto;
                    display: block;
                    margin: 0 10px 0 0;
                    path {
                        stroke: #99a8bc;
                    }
                }
                &:hover, &.active {
                    color: #1C2260;
                    &::after {
                        height: 2px;
                    }
                    svg path {
                        stroke: #1C2260;
                    }
                }
            }
            & + li {
                margin: 0 0 0 30px;
            }
        }
    }
}
.right_btns {
    display: inline-flex;
    align-items: center;
    justify-content:flex-end;
    position: absolute !important;
    right: 30px;
}
.add_button {
    width: auto;
    background: #1C2260;
    color: #ffffff !important;
    height: 40px;
    padding: 0 15px !important;
    border-radius: 6px;
    position: relative;
    &::after {
        display: none;
    }
    // &::before {
    //     content: '+';
    //     position: relative;
    //     margin: 0 10px 0 0;
    //     font-size: 25px;
    //     line-height: 30px;
    //     font-weight: 200;
    // }
    &:hover {
        color: #ffffff !important;
        background: #4265ED;
    }
    & + .add_button {
        margin: 0 0 0 10px;
    }
}