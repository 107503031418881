@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

.app-body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'HK Grotesk', sans-serif;
  height: 100%;
}

.app-body * {
  box-sizing: border-box;
}

.app-html {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  position: relative;
  height: 100%;
  background: #ebeced;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  text-transform: capitalize;
  font-family: 'Hanken Grotesk', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #101f4f;
}
h6 {
  text-transform: capitalize;
  font-family: 'Hanken Grotesk', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #101f4f;
}


*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none !important;
  font-family: 'Hanken Grotesk', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  // text-transform: uppercase;
  color: rgba(16, 31, 79, 0.75);
}

body {
  padding: 0 !important;
  margin: 0 !important;
}

.padded_area {
  width: 100%;
  display: block;
  padding: 62px;
}

label {
  display: block;
  font-family: 'Hanken Grotesk', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #101f4f;
  margin-bottom: 8px;
  // padding: 0 2px;
}
