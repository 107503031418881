.preview_area {
    display: block;
    height: 45vh;
    text-align: center;
    background: #ffffff;
    border-radius: 0px;
    margin: 0 0 15px;
  }
  .imagePreview_box {
    width: auto;
    height: 45vh;
    display: inline-block;
    vertical-align: middle;
    // display: inline-flex;
    // align-items: center;
    // justify-content: center;
    position: relative;
    background: #ffffff;
    margin: 0 auto;
    // overflow: hidden;
    // border: solid 1px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 15%);
    img.preview_image {
      width: auto;
      max-width: 100%;
      max-height: 100%;
      position: relative;
      // z-index: 1;
    }
    .imagePreview {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 400px;
    }
    .badge_img_box {
      clear: both;
      overflow: auto;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      display: block;
      top: 0;
      left: 0;
      i {
        font-style: normal;
      }
    }
    .badge_img {
      position: absolute;
      object-fit: fill;
      width: auto;
      height: auto;
      // max-width: 50%;
      // max-height: 50%;
    }
  }

  img.preview_image {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    // z-index: 1;
  }

  .imagePreview {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 400px;
  }

  .badge_img_box {
    clear: both;
    overflow: auto;
    width: auto;
    height: auto;
    // position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-style: normal;
    }
  }


  .badge_position {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 15px 0 20px;
    position: relative;
    // &::before {
    //     content: '';
    //     position: relative;
    //     width: 0px;
    //     height: 25px;
    //     border-left: 1px dashed #9BB1C8;
    //     order: 2;
    //     margin: 0 auto;
    // }
    h3 {
      width: 100%;
    }
    .Polaris-Button {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      background: #f4f6f8;
      border: 1px solid #b9cbdc;
      box-sizing: border-box;
      border-radius: 4px;
      margin: 0 5px;
      padding: 5px;
      position: relative;
      .Polaris-Button__Content,
      .Polaris-Button__Text {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.Polaris-Button__Text {
          position: relative;
          > span {
            position: absolute;
            width: 13px;
            height: 8px;
            background: #9bb1c8;
            border-radius: 2px;
            &.left {
              left: 0px;
              top: 0;
            }
            &.right {
              right: 0px;
              bottom: 0;
            }
            &.top {
              right: 0;
              top: 0px;
            }
            &.bottom {
              bottom: 0px;
              left: 0;
            }
          }
        }
      }
      &.active {
        border-color: #2e80f2;
        span {
          background: #2e80f2;
        }
      }
    }
    button {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      background: #f4f6f8;
      border: 5px solid #f4f6f8;
      box-shadow: 0 0 0 1px #b9cbdc;
      box-sizing: border-box;
      border-radius: 4px;
      margin: 0 5px;
      padding: 5px;
      position: relative;
      cursor: pointer;
      > span {
        position: absolute;
        width: 13px;
        height: 8px;
        background: #9bb1c8;
        border-radius: 2px;
        &.left {
          left: 5px;
          top: 5px;
        }
        &.right {
          right: 5px;
          bottom: 5px;
        }
        &.top {
          right: 5px;
          top: 5px;
        }
        &.bottom {
          bottom: 5px;
          left: 5px;
        }
      }
      &.active {
        box-shadow: 0 0 0 1px #2e80f2 !important;
        span {
          background: #2e80f2;
        }
      }
    }
  }